import { jwtDecode } from "jwt-decode";

const WSGLogo = require("../assets/icons/workforce_sg_logo.png");
const SSALogo = require("../assets/icons/SSA_logo.png");
const ASMILogo = require("../assets/icons/ASMI_logo.png");


export const logos = {
  ssa:SSALogo,
  asmi:ASMILogo
}


export const formFieldTypes = {
  input: "input",
  text: "text",
  email: "email",
  password: "password",
  button: "button",
  submit: "submit",
  url: "url",
};

export const AppRoutes = {
  Home: "/landing/:association",
  LandingPage: "/landingPage",
  LoginWSG: "/login-wsg",
  LoginTAC: "/login-tac",
  Register: "/register",
  AdminDashboardPage: "/dashboard",
  SubmissionPage: "/submissions",
  CompanyPage: "/company/:id",
  Form: "/form/:association",
  Editor: "/editor",
  JobRolePage: "/editor/:jobRole",
  Success: "/success/:association",
}

export const API_ENDPOINT = process.env.REACT_APP_BE_ENDPOINT_DEP

export const API_Methods = {
  Login: '/login',
  Register: '/register',
  Table_view: '/api/table_pull'
}

export const API_Response_Success = 304;
export const API_Response_OK = 200

export const Response_Message = {
  Success: "Success",
  Error: "Error"
}

export const AlertType = {
  Success: "Success",
  Error: "Error"
}

export function formatTimeDifference(diff: number) {
  const seconds = Math.floor(diff / 1000)
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  if (months > 0) {
    return months === 1 ? '1 month ago' : `${months} months ago`;
  } else if (days > 0) {
    return days === 1 ? '1 day ago' : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
  } else {
    return diff === 1 ? '1 second ago' : `${diff} seconds ago`;
  }
}

export function checkIMOFormat(imo: string): boolean {
  // Regular expression to validate seven-digit number
  const imoRegex = /^\d{7}$/;

  if (!imoRegex.test(imo)) {
    return false; // Not in the correct format
  }

  // Calculate the check digit
  let sum = 0;
  for (let i = 0; i < 6; i++) {
    sum += parseInt(imo.charAt(i)) * (7 - i);
  }
  const checkDigit = sum % 10;

  // Convert the last character of the IMO string to an integer for comparison
  const lastDigit = parseInt(imo.charAt(6));

  // Check if the calculated check digit matches the last digit of the IMO number
  return checkDigit === lastDigit;
}

export const supportEmail = "joel.koh@sgtradex.com"


export function getDaysDiff(baseDate: Date | null, comparisonDate: Date | null) {
  if (!baseDate || !comparisonDate) {
    return 0
  }

  const baseDateOnly = new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate());
  const comparisonDateOnly = new Date(comparisonDate.getFullYear(), comparisonDate.getMonth(), comparisonDate.getDate());

  if (baseDateOnly.getTime() === comparisonDateOnly.getTime()) {
    return 0;
  } else {
    // Different date, calculate days difference
    const daysDifference = Math.ceil((comparisonDateOnly.getTime() - baseDateOnly.getTime()) / (1000 * 60 * 60 * 24));
    return daysDifference;
  }
}


export const getTimeInHHMMFormat = (date: Date | null) => {
  if (!date) {
    return ''
  }
  const hours = String(date.getHours()).padStart(2, '0'); // Ensure two digits for hours
  const minutes = String(date.getMinutes()).padStart(2, '0'); // Ensure two digits for minutes
  return `${hours}:${minutes}`;
};

export function getDecodedAccessToken(token: string): any {
  try {
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
}

export function getCurrentDateTime() {
  const now = new Date();
  const day = now.getDate().toString().padStart(2, '0');
  const month = now.toLocaleString('default', { month: 'short' });
  const year = now.getFullYear();
  const time = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
  return `${day}-${month}-${year} ${time}`;
}
