import React, { RefObject, useState, useEffect, useRef } from "react";

const WSGLogo = require("../assets/icons/workforce_sg_logo.png");

interface NavbarProps {
  navbarRef: RefObject<HTMLDivElement>;
}

const Navbar: React.FC<NavbarProps> = ({ navbarRef }) => {
  const [logoWidth, setLogoWidth] = useState<number>(0);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imgRef.current) {
      setLogoWidth(imgRef.current.offsetWidth);
    }
  }, []);

  return (
    <nav
      ref={navbarRef}
      className="fixed w-full z-10 top-0 shadow flex items-center justify-center px-4"
    >
      <div className=" bg-white container flex justify-between items-center h-full mx-auto">
        <div className="flex-grow flex justify-center">
          <a
            className=" text-black text-base xl:text-xl no-underline hover:no-underline font-extrabold font-sans"
            href="/"
          >
            WSG JRR Form
          </a>
        </div>
        <img
          ref={imgRef}
          src={WSGLogo}
          alt="WSG Application Form"
          className="h-auto w-12 sm:w-24 md:w-32 lg:w-48"
        />
      </div>
    </nav>
  );
};

export default Navbar;
