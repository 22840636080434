import React, { RefObject, useState, useEffect, useRef } from "react";

interface SidebarItemProps {
  label: string;
  selected: boolean;
  onClick: () => void;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  label,
  selected,
  onClick,
}) => {
  return (
    <li
      onClick={onClick}
      className={`py-2 px-4 cursor-pointer hover:bg-yellow-100 lg:hover:bg-transparent ${
        selected ? "font-bold text-green-600" : "text-white"
      }`}
    >
      <span className="text-sm md:text-base">{label}</span>
    </li>
  );
};

interface SidebarMenuProps {
  onSelect: (selectedItem: string) => void;
  pageNum: number;
  navHeight?: number | null;
  changePageClick: (pageNum: number) => void;
  setFormStartHeight: (formStartHeight: number) => void;
  sidebarRef: RefObject<HTMLUListElement>;
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({
  onSelect,
  pageNum,
  navHeight,
  changePageClick,
  setFormStartHeight,
  sidebarRef,
}) => {
  const [selectedItem, setSelectedItem] = useState("Company_Details");
  const handleItemClick = (item: string, pageNumber: number) => {
    setSelectedItem(item);
    onSelect(item);
    changePageClick(pageNumber);
  };

  useEffect(() => {
    if (sidebarRef.current) {
      console.log("Sidebar Height:", sidebarRef.current.offsetHeight);
      setFormStartHeight(sidebarRef.current.offsetHeight);
    } else {
      console.log("Sidebar Height: 0");
    }
    switch (pageNum) {
      case 1:
        setSelectedItem("Company_Details");
        break;
      case 2:
        setSelectedItem("Reskilling_Details");
        break;
      case 3:
        setSelectedItem("Employee_Details");
        break;
      case 4:
        setSelectedItem("Summary");
        break;
      default:
        setSelectedItem("Company_Details");
        break;
    }

    // Log the height of the sidebar element
  }, [pageNum]);

  return (
    <ul
      ref={sidebarRef} // Set the ref to the ul element
      className="list-reset flex justify-around bg-gray-800 text-white py-2 fixed w-full z-20 text-sm md:text-base lg:text-lg xl:text-xl"
      style={{ top: navHeight ? `${navHeight}px` : 0 }} // Dynamically set the top position
    >
      <SidebarItem
        label="Company Details"
        selected={selectedItem === "Company_Details"}
        onClick={() => handleItemClick("Company_Details", 1)}
      />
      <SidebarItem
        label="Reskilling Details"
        selected={selectedItem === "Reskilling_Details"}
        onClick={() => handleItemClick("Reskilling_Details", 2)}
      />
      <SidebarItem
        label="Employee Details"
        selected={selectedItem === "Employee_Details"}
        onClick={() => handleItemClick("Employee_Details", 3)}
      />
      <SidebarItem
        label="Summary"
        selected={selectedItem === "Summary"}
        onClick={() => handleItemClick("Summary", 4)}
      />
    </ul>
  );
};

export default SidebarMenu;
