import React from "react";

interface TextAreaFieldProps {
  id: string;
  name: string;
  placeholder: string;
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  id,
  name,
  placeholder,
  value,
  onChange,
}) => {
  const textAreaClassName = `w-full h-48 bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3 focus:outline-none focus:bg-white focus:border-gray-500`;

  return (
    <div className="my-5">
      <label
        className="block tracking-wide mb-2"
        htmlFor={id}
      >
        {placeholder}
      </label>
      <textarea
        id={id}
        name={name}
        placeholder={placeholder}
        className={textAreaClassName}
        onChange={onChange}
        value={value}
      ></textarea>
    </div>
  );
};

export default TextAreaField;
