import { useNavigate } from "react-router-dom";
import AppColors from "../styles/colors";

interface AdminSidebarTextProps {
  navigationOptionName: String;
  inSelection: Boolean;
}

const AdminSidebarText: React.FC<AdminSidebarTextProps> = ({
  navigationOptionName,
  inSelection,
}) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        borderColor: AppColors.ThemePurple,
      }}
      className={`my-2 px-5 py-2 ${inSelection ? "border-l-4" : ""}`}
    >
      <h1
        style={{
          color: inSelection
            ? AppColors.ThemePurple
            : AppColors.ThemeLightBlack,
        }}
        className={`font-bold text-base xl:text-xl hover:cursor-pointer`}
        onClick={() => {
          console.log("Set navigation");
          navigate("/" + navigationOptionName.toLowerCase());
        }}
      >
        {navigationOptionName}
      </h1>
    </div>
  );
};

export default AdminSidebarText;
