import React, { useEffect} from "react";
import EmployeeForm from "../lib/Interfaces/EmployeeForm";
import CompanyDetailsFields from "../lib/Interfaces/CompanyDetailsFields";
import ReskillingDetailsFields from "../lib/Interfaces/ReskillingDetailsFields";
import { submitFinalForm } from "../api/API";
import { useParams } from "react-router-dom";

interface SummaryDetailsFormProps {
  handleSubmitFormButton: any;
  handlePrevPageButton: () => void;
  employeeData: EmployeeForm[];
  companyData?: CompanyDetailsFields;
  reskillData?: ReskillingDetailsFields;
  setFinalData: Function;
}

const SummaryDetailsForm: React.FC<SummaryDetailsFormProps> = ({
  handleSubmitFormButton,
  handlePrevPageButton,
  employeeData,
  companyData,
  reskillData,
  setFinalData,
}) => {
  useEffect(() => {
    setFinalData({
      employees: employeeData,
      company: companyData,
      reskilling: reskillData,
    });
  }, [employeeData, companyData, reskillData]);
  const {association} = useParams()
  console.log("Summary employees", employeeData, companyData, reskillData);
  const submitForm = async () => {
    console.log(
      "final_data:",
      JSON.stringify({
        company: companyData,
        reskilling: reskillData,
        employees: employeeData,
        tac: association
      })
    );
    localStorage.clear(); // Clear the local storage
    try {
      const response = await submitFinalForm({
        employees: employeeData,
        company: companyData,
        reskilling: reskillData,
        tac: association
      });

      const data = await response.msg;
      console.log("Success:", data);
      handleSubmitFormButton(association); // call the provided submit handler
    } catch (error) {
      console.error("Error:", error);
      // You can handle the error here if needed
    }
  };
  return (
    <div
      id="Summary"
      className="py-6 px-8 mt-6 lg:mt-0 rounded shadow bg-gray-900"
    >
      <h2 className="font-bold break-normal text-2xl lg:text-4xl mb-6 text-white">
        Summary
      </h2>
      <div className="border rounded-lg p-4 my-4 bg-gray-100">
        <h2 className="text-xl font-bold mb-2">Company Details</h2>
        <p>
          <span className="font-bold">Company Name:</span>{" "}
          {companyData?.company_name}
        </p>
        <p>
          <span className="font-bold">Company UEN:</span>{" "}
          {companyData?.company_uen}
        </p>
        <p>
          <span className="font-bold">Number of Singapore Citizens & PR:</span>{" "}
          {companyData?.no_singaporecitizen_pr}
        </p>
        <p>
          <span className="font-bold">Total Headcount:</span>{" "}
          {companyData?.total_headcount}
        </p>
        <p>
          <span className="font-bold">
            Uploaded Company Organisation Chart:
          </span>{" "}
          {companyData?.file_name}
        </p>
      </div>
      <div className="border rounded-lg p-4 my-4 bg-gray-100">
        <h2 className="text-xl font-bold mb-2">Reskilling Details</h2>
        <p>
          <span className="font-bold">Solution Provider:</span>{" "}
          {reskillData?.solution_provider}
        </p>
        <p>
          <span className="font-bold">Solution Provider UEN:</span>{" "}
          {reskillData?.sp_company_uen}
        </p>
        <p>
          <span className="font-bold">
            Company description and key business areas:
          </span>{" "}
          {reskillData?.sp_company_details}
        </p>
        <p>
          <span className="font-bold">
            Departments/job roles in which locals are employed in company:
          </span>{" "}
          {reskillData?.department_of_locals}
        </p>

        <p>
          <span className="font-bold">
            Details of business and/or workforce transformation:
          </span>{" "}
          {reskillData?.details_of_business}
        </p>
        <p>
          <span className="font-bold">
            Are you tapping on any other government funding?:
          </span>{" "}
          {reskillData?.government_fund}
        </p>
        <p>
          <span className="font-bold">Other government funding details?:</span>{" "}
          {reskillData?.other_government_fund_details}
        </p>
        <p>
          <span className="font-bold">Number of affected workers:</span>{" "}
          {reskillData?.no_of_workers_affected}
        </p>
        <p>
          <span className="font-bold">Point of Contact Name:</span>{" "}
          {reskillData?.poc_contact_name}
        </p>
        <p>
          <span className="font-bold">Point of Contact Designation:</span>{" "}
          {reskillData?.poc_contact_designation}
        </p>
        <p>
          <span className="font-bold">Point of Contact Number:</span>{" "}
          {reskillData?.poc_contact_number}
        </p>
        <p>
          <span className="font-bold">Point of Contact Email:</span>{" "}
          {reskillData?.poc_contact_email}
        </p>
        <p>
          <span className="font-bold">Project Duration:</span>{" "}
          {reskillData?.project_duration} {reskillData?.project_duration_unit}
        </p>
      </div>
      {employeeData.map((employee, index) => {
        return (
          <div
            key={employee.id}
            className="border rounded-lg p-4 my-4 bg-gray-100"
          >
            <h2 className="text-xl font-bold mb-2">Employee Information</h2>
            <p>
              <span className="font-bold">Employee Number:</span> {employee.id}
            </p>
            <p>
              <span className="font-bold">Name:</span> {employee.name}
            </p>
            <p>
              <span className="font-bold">NRIC:</span> {employee.nric}
            </p>
            <p>
              <span className="font-bold">Date of Birth:</span>{" "}
              {String(employee.dob)}
            </p>
            <p>
              <span className="font-bold">Date of Hire:</span>{" "}
              {String(employee.doh)}
            </p>
            <p>
              <span className="font-bold">Citizenship:</span>{" "}
              {employee.citizenship}
            </p>
            <p>
              <span className="font-bold">Highest Qualification:</span>{" "}
              {employee.highest_qualification}
            </p>
            <p>
              <span className="font-bold">Job Role:</span> {employee.job_role}
            </p>
            <p>
              <span className="font-bold">Designation (Before):</span>{" "}
              {employee.b_designation}
            </p>
            <p>
              <span className="font-bold">Department (Before):</span>{" "}
              {employee.b_department}
            </p>
            <p>
              <span className="font-bold">Salary (Before):</span>{" "}
              {String(employee.b_salary)}
            </p>
            <p>
              <span className="font-bold">Job Functions (Before):</span>{" "}
              {employee.b_selected_job_functions
                .map((jf) => jf.desc)
                .join(", ")}
            </p>
            <p>
              <span className="font-bold">Designation (After):</span>{" "}
              {employee.a_designation}
            </p>
            <p>
              <span className="font-bold">Department (After):</span>{" "}
              {employee.a_department}
            </p>
            <p>
              <span className="font-bold">Salary (After):</span>{" "}
              {String(employee.a_salary)}
            </p>
            <p>
              <span className="font-bold">Job Functions (After):</span>{" "}
              {employee.a_selected_job_functions
                .map((jf) => jf.desc)
                .join(", ")}
            </p>
            <p>
              <span className="font-bold">Key Differences:</span>{" "}
              {employee.key_differences}
            </p>
            <p>
              <span className="font-bold">New Skills:</span>{" "}
              {employee.new_skills}
            </p>
          </div>
        );
      })}

      <div className="flex justify-between mt-4">
        <button
          type="button"
          className="bg-gray-500 hover:bg-gray-300 text-white font-bold py-2 px-4 rounded text-xl"
          onClick={handlePrevPageButton}
        >
          Previous
        </button>
        <button
          className="bg-green-500 hover:bg-green-300 text-white font-bold py-5 px-7 rounded text-xl"
          onClick={submitForm}
        >
          Submit Form
        </button>
      </div>
    </div>
  );
};

export default SummaryDetailsForm;
