import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddEmployeeForm from "./AddEmployee";
import EmployeeForm from "../lib/Interfaces/EmployeeForm";

interface EmployeeDetailsFormProps {
  handleNextPageButton: () => void;
  handlePrevPageButton: () => void;
  affectedWorkersNum: number;
  selectedSolution: string;
  setEmployeeProfilesNum: Function;
  handleAddEmployee: Function;
  handleUpdateEmployee: Function;
  employees: EmployeeForm[];
}

const EmployeeDetailsForm: React.FC<EmployeeDetailsFormProps> = ({
  handleNextPageButton,
  handlePrevPageButton,
  affectedWorkersNum,
  setEmployeeProfilesNum,
  handleAddEmployee,
  handleUpdateEmployee,
  employees,
  selectedSolution,
}) => {
  const [showEmployeeForm, setShowEmployeeForm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [employeeToEdit, setEmployeeToEdit] = useState<EmployeeForm | null>(
    null
  );

  const MAX_EMPLOYEES = affectedWorkersNum;

  const handleAddEmployeeNew = () => {
    if (employees.length < MAX_EMPLOYEES) {
      setEmployeeToEdit(null); // Reset the edit state when adding a new employee
      setShowEmployeeForm(true);
    } else {
      // Display error message or handle the case when maximum employees are added
      console.log("Maximum number of employees reached");
      toast.error("Maximum number of employees reached. Cannot add more.");
      setShowEmployeeForm(false);
      setShowError(true);
    }
  };
  const handleEmployeeFormClose = () => {
    setShowEmployeeForm(false);
  };

  const handleAddEmployeeSubmit = (data: EmployeeForm) => {
    if (employeeToEdit) {
      // Edit logic
      handleUpdateEmployee(data);
    } else {
      // Add logic
      if (employees.length < MAX_EMPLOYEES) {
        //Issue is here. HandleAddEmployee is from the parent component and will help you add him in.
        handleAddEmployee(data);
        setEmployeeProfilesNum(employees.length);
      } else {
        setShowError(true);
      }
    }
    setShowEmployeeForm(false);
  };

  const handleEditEmployee = (employee: EmployeeForm) => {
    setEmployeeToEdit(employee);
    setShowEmployeeForm(true);
  };
  return (
    <div id="Employee_Details" className="py-6 px-8 mt-6 lg:mt-0">
      <h2 className="font-bold break-normal text-2xl lg:text-4xl mb-6">
        Employee Details
      </h2>
      {!showEmployeeForm && (
        <form className="w-full p-10 px-0">
          <h3 className="text-lg font-medium pb-1">Employee Information</h3>
          <br></br>
          <h4 className="font-bold text-md">
            Number of SC/PR employees to undergo JRR: {affectedWorkersNum}
          </h4>
          <br></br>
          <div className="w-full container mx-auto flex flex-wrap items-center">
            <h4 className="text-md mr-5">
              Employee Profiles: ({employees.length}/{affectedWorkersNum})
            </h4>
            <button
              type="button"
              className="bg-green-500 hover:bg-gray-300 text-white font-bold py-2 px-4 rounded text-md"
              onClick={() => {
                handleAddEmployeeNew();
              }}
            >
              Add Employee
            </button>
          </div>
          <div className="min-h-24">
            {employees.length > 0 ? (
              employees.map((employee, index) => {
                console.log("index:", index);
                console.log("Employee name:", employee.name);
                console.log("Employee at index 0:", employees[0]);
                console.log("Employee at index 1:", employees[1]); // Add this line
                return (
                  <div
                    key={index}
                    className="mb-2 mt-5 flex items-center text-black"
                  >
                    {/* Render employee details here */}
                    <input
                      type="text"
                      value={employee.name}
                      readOnly
                      className="p-2 border rounded w-[50%] mr-2 text-black"
                    />
                    <button
                      type="button"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      onClick={() => handleEditEmployee(employee)}
                    >
                      Edit
                    </button>
                    {/* Render other employee details here */}
                  </div>
                );
              })
            ) : (
              <div className="h-24 flex-col flex justify-center">
                <p className="my-2">No employee profiles</p>
              </div>
            )}
          </div>

          <div className="flex justify-between mt-4">
            <button
              type="button"
              className="bg-gray-500 hover:bg-gray-300 text-white font-bold py-2 px-4 rounded text-xl"
              onClick={handlePrevPageButton}
            >
              Previous
            </button>
            <button
              className="bg-green-500 hover:bg-green-300 text-white font-bold py-5 px-7 rounded text-xl"
              onClick={handleNextPageButton}
            >
              Next
            </button>
          </div>
        </form>
      )}
      {showEmployeeForm && (
        <AddEmployeeForm
          onClose={handleEmployeeFormClose}
          setShowEmployeeForm={setShowEmployeeForm}
          handleAddEmployeeSubmit={handleAddEmployeeSubmit}
          employeeID={employees.length}
          selectedSolution={selectedSolution}
          initialData={employeeToEdit}
        />
      )}
    </div>
  );
};

export default EmployeeDetailsForm;
