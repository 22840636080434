import React, { useRef, useState, useEffect } from "react";

import Navbar from "../components/Navbar";
import SidebarMenu from "../components/SidebarMenu";
import CompanyDetailsForm from "../components/CompanyDetails";
import ReskillingDetailsForm from "../components/ReskillingDetails";
import EmployeeDetailsForm from "../components/EmployeeDetails";
import SummaryDetailsForm from "../components/SummaryDetails";

import EmployeeForm from "../lib/Interfaces/EmployeeForm";
import CompanyDetailsFields from "../lib/Interfaces/CompanyDetailsFields";
import ReskillingDetailsFields from "../lib/Interfaces/ReskillingDetailsFields";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "../lib/constants";
import { checkTACExists } from "../api/API";

const Form: React.FC = () => {
  const navbarRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLUListElement>(null);
  const [navHeight, setNavHeight] = useState<number | null>(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [pageNum, setPageNum] = useState<number>(1);
  const [affectedWorkersNum, setAffectedWorkersNum] = useState<number>(0);
  const [employees, setEmployees] = useState<EmployeeForm[]>([]);
  const [companyForm, setCompanyForm] = useState<CompanyDetailsFields>();
  const [reskillForm, setReskillForm] = useState<ReskillingDetailsFields>();
  const [formStartHeight, setFormStartHeight] = useState<number | null>(0);
  const [employeeProfilesNum, setEmployeeProfilesNum] = useState<number>(0);
  const [selectedSolution, setSelectedSolution] = useState("");
  const {association} = useParams()
  const [finalData, setFinalData] = useState({
    company: useState<CompanyDetailsFields>(),
    reskilling: useState<ReskillingDetailsFields>(),
    employees: useState<EmployeeForm[]>([]),
  });
  const navigate = useNavigate();
  const handleSelectItem = (item: string) => {
    setSelectedItem(item);
  };
  const handleNextPageButton_company = (data: CompanyDetailsFields) => {
    console.log("Company Details:", data);
    setCompanyForm(data);
    setPageNum((prevPageNum) => prevPageNum + 1);
    console.log(`pageNum = ${pageNum}`);
    // Handle the data as needed, such as updating the state or navigating to the next page
  };
  const handleSelectedSolution = (sol: string) => {
    setSelectedSolution(sol);
  };
  const handleNextPageButton_reskill = (data: ReskillingDetailsFields) => {
    console.log("Reskill Details:", data);
    setReskillForm(data);
    setPageNum((prevPageNum) => prevPageNum + 1);
    console.log(`pageNum = ${pageNum}`);
  };
  const handleNextPageButton = () => {
    setPageNum((prevPageNum) => prevPageNum + 1);
    console.log(`pageNum = ${pageNum}`);
  };

  const handlePrevPageButton = () => {
    setPageNum((prevPageNum) => (prevPageNum > 1 ? prevPageNum - 1 : 1));
    console.log(`pageNum = ${pageNum}`);
  };

  const handleAddEmployee = (newEmployee: EmployeeForm) => {
    setEmployees((prev) => {
      const newEmployees = [...prev, newEmployee];
      console.log(newEmployees);
      return newEmployees;
    });
  };

  const handleUpdateEmployee = (newEmployee: EmployeeForm) => {
    setEmployees((prev) => {
      const updatedEmployees = prev.map((employee) =>
        employee.id === newEmployee.id ? newEmployee : employee
      );
      return updatedEmployees;
    });
  };
  const handleChangePageClick = (newPageNum: number) => {
    setPageNum(newPageNum);
  };

  const handleSubmitFormButton = (association: String) => {
    console.log("Form submitted!", finalData);
    localStorage.clear(); // Clear the local storage
    navigate('/success/' + association);
    // Call the submission handler function with the updated data
  };

  useEffect(() => {
    const checkTAC = async () => {
      const tacExists = await checkTACExists(association ?? "")
      if (tacExists.status != 200) {
        //RenderPageNotFound
      }
      console.log(tacExists)
    }
    const updateNavHeight = () => {
      if (navbarRef.current) {
        setNavHeight(navbarRef.current.clientHeight);
        console.log("navHeight", navHeight);
      }
    };
    checkTAC()
    // Initial height calculation
    updateNavHeight();

    // Update height on window resize
    window.addEventListener("resize", updateNavHeight);

    return () => {
      window.removeEventListener("resize", updateNavHeight);
    };
  }, []);
  useEffect(() => {
    const updateSideBarHeight = () => {
      if (sidebarRef.current) {
        setFormStartHeight(sidebarRef.current.offsetHeight);
        console.log("formStartHeight", formStartHeight);
      }
    };

    // Initial height calculation
    updateSideBarHeight();

    // Update height on window resize
    window.addEventListener("resize", updateSideBarHeight);

    return () => {
      window.removeEventListener("resize", updateSideBarHeight);
    };
  }, []);

  return (
    <div className=" text-gray-100 tracking-wider leading-normal h-max">
      <Navbar navbarRef={navbarRef} />
      <SidebarMenu
        onSelect={handleSelectItem}
        pageNum={pageNum}
        navHeight={navHeight}
        changePageClick={handleChangePageClick}
        setFormStartHeight={setFormStartHeight}
        sidebarRef={sidebarRef}
      />
      <div
        className="container mx-auto px-2 pt-8 mt-8"
        style={{
          marginTop:
            navHeight && formStartHeight
              ? `${navHeight + formStartHeight}px`
              : "0",
        }}
      >
        {/* Form and details */}
        <div className="w-full flex flex-col text-gray-900 leading-normal 2xl:px-96 xl:px-60">
          <div className="mb-8 lg:mb-0">
            {pageNum === 1 && (
              <CompanyDetailsForm
                handleNextPageButton={handleNextPageButton_company}
                initialCompanyData={companyForm}
              />
            )}
            {pageNum === 2 && (
              <ReskillingDetailsForm
                handleNextPageButton={handleNextPageButton_reskill}
                handlePrevPageButton={handlePrevPageButton}
                setAffectedWorkersNum={setAffectedWorkersNum}
                handleSelectedSolution={handleSelectedSolution}
                affectedWorkers={affectedWorkersNum}
                initialReskillData={reskillForm}
              />
            )}
            {pageNum === 3 && (
              <EmployeeDetailsForm
                handleNextPageButton={handleNextPageButton}
                handlePrevPageButton={handlePrevPageButton}
                affectedWorkersNum={affectedWorkersNum}
                selectedSolution={selectedSolution}
                setEmployeeProfilesNum={setEmployeeProfilesNum}
                handleAddEmployee={handleAddEmployee}
                handleUpdateEmployee={handleUpdateEmployee}
                employees={employees}
              />
            )}
            {pageNum === 4 && (
              <SummaryDetailsForm
                handleSubmitFormButton={handleSubmitFormButton}
                handlePrevPageButton={handlePrevPageButton}
                employeeData={employees}
                companyData={companyForm}
                reskillData={reskillForm}
                setFinalData={setFinalData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
