import { ConnectingAirportsOutlined, Logout } from "@mui/icons-material";
import AppColors from "../styles/colors";
import AdminSidebarText from "./AdminSidebarText";
import { Image } from "../styles/global";
import { useContext } from "react";
import { LoginTokenContext } from "../contexts/LoginTokenContext";
import { useNavigate } from "react-router-dom";
import { jwtDecode, JwtPayload } from "jwt-decode";

const WSGLogo = require("../assets/icons/workforce_sg_logo.png");
const SSALogo = require("../assets/icons/SSA_logo.png");
const SGTDLogo = require("../assets/icons/SGTraDex-logo.png");

interface AdminSidebarProps {
  selected: String;
}

const AdminSidebar: React.FC<AdminSidebarProps> = ({ selected }) => {
  const [token, setToken, encodedToken, setEncodedToken] =
    useContext(LoginTokenContext);
  const navigate = useNavigate();
  function handleLogout() {
    setToken("");
    window.localStorage.clear();
    const role = jwtDecode<JwtPayload & {sub: {role:string}}>(token).sub.role;
    if (role == "WSGUser") {
      navigate("/login-wsg");
    } else if (role == "TACUser") {
      navigate("/login-tac");
    }
  }

  return (
    <>
      <div
        style={{
          height: "calc(100vh - 60px)",
          minHeight: "min-content",
          minWidth: "min-content",
        }}
        className="w-full bg-white rounded-md flex flex-col p-2 sticky top-6"
      >
        <div className="flex flex-row justify-center p-2">
          <div className="w-1/2 m-2">
            <img src={WSGLogo} />
          </div>
        </div>
        <div className="mx-auto w-min h-min flex-grow flex flex-col">
          <div className="content-center flex-grow">
            <AdminSidebarText
              inSelection={selected == "Dashboard"}
              navigationOptionName="Dashboard"
            />
            <AdminSidebarText
              inSelection={selected == "Submissions"}
              navigationOptionName="Submissions"
            />
            <AdminSidebarText
              inSelection={selected == "Editor"}
              navigationOptionName="Editor"
            />
          </div>
          <div
            style={{
              borderColor: AppColors.ThemePurple,
            }}
            className={`px-5 py-2 mt-auto`}
          >
            <h1
              className={`font-bold text-base xl:text-xl hover:cursor-pointer hover:text-red-600`}
              onClick={() => {
                handleLogout();
              }}
            >
              Logout
            </h1>
          </div>
        </div>
        <div className="p-5 flex-shrink">
          <p className="text-base">Powered By: </p>
          <div className="w-1/3">
            <img src={SGTDLogo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSidebar;
