import React, { useState, useEffect } from "react";
import InputField from "./FormInputFields";
import MultiCheckbox from "./FormCheckBox";
import SelectedValues from "./FormSelectedValues";
import EmployeeForm from "../lib/Interfaces/EmployeeForm";
import Option from "../lib/Interfaces/CheckboxOptions";
import TextAreaField from "./FormTextArea";
import DropdownField from "./FormDropDownFields";
import JobFunction from "../lib/Interfaces/JobFunction";
import { getJobFunctions } from "../api/API";
import JobRoleOptionMapping from "../lib/Interfaces/JobRoleOptionMapping";
import MultiCardWindow from "./MultiCardWindow";

interface AddEmployeeFormProps {
  onClose: () => void;
  setShowEmployeeForm: (show: boolean) => void;
  handleAddEmployeeSubmit: Function;
  employeeID: number;
  selectedSolution: string;
  initialData?: EmployeeForm | null;
}

const AddEmployeeForm: React.FC<AddEmployeeFormProps> = ({
  onClose,
  setShowEmployeeForm,
  handleAddEmployeeSubmit,
  employeeID,
  initialData,
  selectedSolution,
}) => {
  // Call to display API for job functions
  // Function to load options from local storage
  const [bOptions, setBOptions] = useState<Option[]>([]);
  const [aOptions, setAOptions] = useState<Option[]>([]);
  const [lastJobRole, setLastJobRole] = useState<string>("");

  const handleCheckboxChange = (index: number, values: JobFunction[]) => {
    console.log(values);
    setFormData((prevState) => ({
      ...prevState,
      b_selected_job_functions:
        index === 0 ? values : prevState.b_selected_job_functions,
      a_selected_job_functions:
        index === 1 ? values : prevState.a_selected_job_functions,
    }));
  };
  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleBOptionsChange = (newOptions: Option[]) => {
    setBOptions(newOptions);
  };
  const handleAOptionsChange = (newOptions: Option[]) => {
    setAOptions(newOptions);
  };

  const [formData, setFormData] = useState<EmployeeForm>({
    id: employeeID + 1,
    name: "",
    nric: "",
    dob: new Date(),
    doh: new Date(),
    citizenship: "",
    highest_qualification: "",
    job_role: "",
    b_designation: "",
    b_department: "",
    b_salary: 0,
    b_selected_job_functions: [],
    a_designation: "",
    a_department: "",
    a_salary: 0,
    a_selected_job_functions: [],
    key_differences: "",
    new_skills: "",
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
      setLastJobRole(initialData.job_role);
      handleGetJobFunctions(initialData.job_role);
    }
  }, [initialData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const refreshJobFunctions = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value != lastJobRole) {
      setBOptions((prev) => {
        return prev.filter((option) => option.value.new);
      });

      setAOptions((prev) => {
        return prev.filter((option) => option.value.new);
      });

      setFormData((prevState) => ({
        ...prevState,
        b_selected_job_functions: [],
        a_selected_job_functions: [],
      }));
    }
  };
  const handleGetJobFunctions = async (jobRole: string) => {
    const jobMapping = await getJobFunctions(selectedSolution, jobRole);
    let bStoredOptions = localStorage.getItem("bOption");
    let aStoredOptions = localStorage.getItem("aOption");
    bStoredOptions = JSON.parse(bStoredOptions ?? "[]");
    aStoredOptions = JSON.parse(aStoredOptions ?? "[]");
    let bParsedOptions: JobRoleOptionMapping[] = Array.isArray(bStoredOptions)
      ? bStoredOptions
      : [];
    let aParsedOptions: JobRoleOptionMapping[] = Array.isArray(aStoredOptions)
      ? aStoredOptions
      : [];

    const existingBOptions =
      bParsedOptions.find(
        (po) => po.jobRole.toLowerCase() === jobRole.toLowerCase()
      )?.options || [];

    const existingAOptions =
      aParsedOptions.find(
        (po) => po.jobRole.toLowerCase() === jobRole.toLowerCase()
      )?.options || [];

    if (jobMapping.status === 200) {
      let newBOptions = jobMapping.data.job_functions
        .filter((jf: any) => jf.before)
        .filter((jf: any) => !bOptions.find((opt) => opt.value.desc == jf.desc))
        .map((jf: any) => ({
          label: jf.desc,
          value: { desc: jf.desc, new: false },
        }));

      let newAOptions = jobMapping.data.job_functions
        .filter((jf: any) => !jf.before)
        .filter(
          (jf: any) => !aOptions.find((opt) => opt.value.desc === jf.desc)
        )
        .map((jf: any) => ({
          label: jf.desc,
          value: { desc: jf.desc, new: false },
        }));

      existingAOptions.forEach((opt) => {
        if (!aOptions.find((aopt) => aopt.value.desc === opt.value.desc)) {
          newAOptions.push(opt);
        }
      });
      existingBOptions.forEach((opt) => {
        if (!bOptions.find((bopt) => bopt.value.desc === opt.value.desc)) {
          newBOptions.push(opt);
        }
      });

      setBOptions((prev) => [...prev, ...newBOptions]);
      setAOptions((prev) => [...prev, ...newAOptions]);
    } else {
      setBOptions((prev) => [...existingBOptions]);
      setAOptions((prev) => [...existingAOptions]);
      console.log("Error fetching job functions");
    }
  };
  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const updateOptions = () => {
    //Handle if there is no existing option (ab) for job role. Add it in
    let existingBOption: string | null = localStorage.getItem("bOption");
    let existingAOption: string | null = localStorage.getItem("aOption");
    let bParsedOptions: JobRoleOptionMapping[] = [];
    let aParsedOptions: JobRoleOptionMapping[] = [];

    if (existingBOption) {
      try {
        bParsedOptions = JSON.parse(existingBOption);

        if (!Array.isArray(bParsedOptions)) {
          throw new Error("Parsed data is not an array of strings");
        }
      } catch (error) {
        console.error("Error parsing bOption from localStorage:", error);
        bParsedOptions = [];
      }
    }
    let newBOptions: Option[] = [];

    if (
      bParsedOptions.find(
        (po) => po.jobRole.toLowerCase() == formData.job_role.toLowerCase()
      )
    ) {
      bParsedOptions = bParsedOptions.map((po) => {
        if (po.jobRole.toLowerCase() == formData.job_role.toLowerCase()) {
          bOptions.forEach((bopt) => {
            if (
              !po.options.find((opt) => opt.value == bopt.value) &&
              bopt.value.new
            ) {
              newBOptions.push(bopt);
            }
          });
          po.options = newBOptions;
        }
        return po;
      });
    } else {
      bParsedOptions.push({
        jobRole: formData.job_role.toLowerCase(),
        options: bOptions.filter((opt) => opt.value.new),
      });
    }

    if (existingAOption) {
      try {
        aParsedOptions = JSON.parse(existingAOption);

        if (!Array.isArray(aParsedOptions)) {
          throw new Error("Parsed data is not an array of strings");
        }
      } catch (error) {
        console.error("Error parsing bOption from localStorage:", error);
        aParsedOptions = [];
      }
    }

    let newAOptions: Option[] = [];

    if (
      aParsedOptions.find(
        (po) => po.jobRole.toLowerCase() == formData.job_role.toLowerCase()
      )
    ) {
      aParsedOptions = aParsedOptions.map((po) => {
        if (po.jobRole.toLowerCase() == formData.job_role.toLowerCase()) {
          aOptions.forEach((aopt) => {
            if (
              !po.options.find((opt) => opt.value == aopt.value) &&
              aopt.value.new
            ) {
              newAOptions.push(aopt);
            }
          });
          po.options = newAOptions;
        }
        return po;
      });
    } else {
      aParsedOptions.push({
        jobRole: formData.job_role.toLowerCase(),
        options: aOptions.filter((opt) => opt.value.new),
      });
    }

    localStorage.setItem("bOption", JSON.stringify(bParsedOptions));
    localStorage.setItem("aOption", JSON.stringify(aParsedOptions));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form submitted!", formData.name);
    updateOptions();
    setShowEmployeeForm(false);
    handleAddEmployeeSubmit(formData); // Call the submission handler function with the updated data
  };

  return (
    <form className="w-full p-10 px-0" onSubmit={handleSubmit}>
      <h2 className="text-xl font-bold mb-4">
        {`Add Employee #${
          initialData != null ? initialData.id : employeeID + 1
        }`}
      </h2>
      <div className="mb-4">
        <InputField
          id={`employeeName-${
            initialData != null ? initialData.id : employeeID + 1
          }`}
          name="name"
          type="text"
          placeholder="Employee Name"
          value={formData.name ? formData.name : ""}
          onChange={handleChange}
        />
        <p className="text-xl font-bold mb-6">Job Role</p>
        <InputField
          id={`employeeJobRole-${
            initialData != null ? initialData.id : employeeID + 1
          }`}
          name="job_role"
          type="text"
          placeholder="Job Role"
          value={formData.job_role}
          onChange={handleChange}
          onBlur={(event) => {
            setLastJobRole(event.target.value);
            handleGetJobFunctions(event.target.value);
          }}
          onBlurCapture={refreshJobFunctions}
        />
        <div className="border border-black p-4 rounded-md mb-4">
          <p className="text-xl font-bold mb-6">Current Job Scope</p>
          <InputField
            id={`employeeBDesignation-${
              initialData != null ? initialData.id : employeeID + 1
            }`}
            name="b_designation"
            type="text"
            placeholder="Designation"
            value={formData.b_designation}
            onChange={handleChange}
          />
          <InputField
            id={`employeeBDepartment-${
              initialData != null ? initialData.id : employeeID + 1
            }`}
            name="b_department"
            type="text"
            placeholder="Department"
            value={formData.b_department}
            onChange={handleChange}
          />
         
          <MultiCardWindow
            options={bOptions}
            selectedValues={formData.b_selected_job_functions}
            onChange={(values) => handleCheckboxChange(0, values)}
            onOptionsChange={handleBOptionsChange}
          />
        </div>
        <div className="border border-black p-4 rounded-md mb-4">
          <p className="text-xl font-bold mb-6">Future Job Scope</p>
          <InputField
            id={`employeeADesignation-${
              initialData != null ? initialData.id : employeeID + 1
            }`}
            name="a_designation"
            type="text"
            placeholder="Designation"
            value={formData.a_designation}
            onChange={handleChange}
          />
          <InputField
            id={`employeeADepartment-${
              initialData != null ? initialData.id : employeeID + 1
            }`}
            name="a_department"
            type="text"
            placeholder="Department"
            value={formData.a_department}
            onChange={handleChange}
          />
          
          <MultiCardWindow
            options={aOptions}
            selectedValues={formData.a_selected_job_functions}
            onChange={(values) => handleCheckboxChange(1, values)}
            onOptionsChange={handleAOptionsChange}
          />
        </div>

        <TextAreaField
          id={`employeeKeyDifferences-${
            initialData != null ? initialData.id : employeeID + 1
          }`}
          name="key_differences"
          placeholder="Key Differences"
          value={formData.key_differences}
          onChange={handleTextAreaChange}
        />
        <InputField
          id={`employeenew_skills-${
            initialData != null ? initialData.id : employeeID + 1
          }`}
          name="new_skills"
          type="text"
          placeholder="New Skills"
          value={formData.new_skills}
          onChange={handleChange}
        />
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Save
        </button>
        <button
          type="button"
          onClick={onClose}
          className="ml-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};
export default AddEmployeeForm;

{
  /* <div className="mb-4">
              <label
                htmlFor="email"
                className="block font-bold mb-1 text-white"
              >
                Email:
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="border border-gray-400 p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="phone" className="block font-bold mb-1">
                Phone:
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="border border-gray-400 p-2 w-full"
              />
            </div> */
}
