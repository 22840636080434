import axios, { AxiosError } from 'axios';
import qs from 'qs'
import SummaryDetails from '../lib/Interfaces/SummaryDetails';

const { v4: uuidv4 } = require('uuid');


export const API = process.env.REACT_APP_BE_ENDPOINT_DEP

export async function loginWSG(email: String, password: String) {
    try {
        const response = await axios.post(API + '/login-wsg', {
            email,
            password  
        })
        return response

    } catch (e) {
        return {
            status: 401,
            msg: "Invalid Credentials",
            data:""
        }
    }
}


export async function loginTAC(email: String, password: String) {
    try {
        const response = await axios.post(API + '/login-tac', {
            email,
            password  
        })
        return response

    } catch (e) {
        return {
            status: 401,
            msg: "Invalid Credentials",
            data:""
        }
    }
}

export async function checkTACExists(tac: string) {
    try {
        const response = await axios.get(API + '/tac/' + tac)
        return response
    } catch (e) {
        return {
            status: 404,
            msg: "TAC does not exists",
            data:""
        }
    }
}

export async function getFormDetails(formId: number, token: String) {
    try {
        const response = await axios.get(API + `/form/${formId}`, {
            headers: {
                Authorization:"Bearer " + token
            }
        })
        return response
    } catch (e: any) {
        if (e.response) {
            return { 
              status: e.response.status,
              data: []
             };
          } else {
            return { 
              status: 500,
              data: []
             }; 
          }

    }
}

export async function getSpecificJobRoles(jobRole: String, token: String) {
    try {
        const response = await axios.get(API + `/job-roles/${jobRole}`, {
            headers: {
                Authorization:"Bearer " + token
            }
        })
        console.log(response)
        return response
    } catch (e: any) {
        if (e.response) {
            return { 
              status: e.response.status,
              data: []
             };
          } else {
            return { 
              status: 500,
              data: []
             }; 
          }

    }
}

export async function getSolutionProviderInfo(jobRole:String, solProvId: number,  token: String) {
    try {
        const response = await axios.get(API + `/solution-provider/${jobRole}/${solProvId}`, {
            headers: {
                Authorization:"Bearer " + token
            }
        })

        console.log(response)
        return response
    } catch (e: any) {
        if (e.response) {
            return { 
              status: e.response.status,
              data: []
             };
          } else {
            return { 
              status: 500,
              data: []
             }; 
          }

    }
}

export async function getSolutionProvidersForJobRole(jobRole: String, token: String) {
    try {
        const response = await axios.get(API + '/solution-provider/' + jobRole, {
            headers: {
                Authorization:"Bearer " + token
            }
        })
        return response
    } catch (e: any) {
        if (e.response) {
            return { 
              status: e.response.status,
              data: []
             };
          } else {
            return { 
              status: 500,
              data: []
             }; 
          }

    }

}

export async function getSubmissionSummaries(token: String) {
    try {
        const response = await axios.get(API + '/submission-summaries', {
            headers: {
                Authorization:"Bearer " + token
            }
        })

        console.log(response)
    
        return response
    } catch (e: any) {
        if (e.response) {
          return { 
            status: e.response.status,
            data: []
           };
        } else {
          return { 
            status: 500,
            data: []
           }; 
        }
      }
}

export async function getSubmissionCount(token: String) {
    try {
        const response = await axios.get(API + '/submission-count', {
            headers: {
                Authorization:"Bearer " + token
            }
        })
    
        return response
    } catch (e: any) {
        if (e.response) {
          return { 
            status: e.response.status,
            data: []
           };
        } else {
          return { 
            status: 500,
            data: []
           }; 
        }
      }
}


export async function getAllJobRoles(token: String) {
    try {
        const response = await axios.get(API + '/job-roles', {
            headers: {
                Authorization:"Bearer " + token
            }
        })
    
        return response
    } catch (e: any) {
        if (e.response) {
          return { 
            status: e.response.status,
            data: []
           };
        } else {
          return { 
            status: 500,
            data: []
           }; 
        }
      }
}

export async function checkJWT(token: string) {
    try {
      const response = await axios.get(API + '/check-jwt', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return response;
    } catch (e: any) {
      if (e.response) {
        return { status: e.response.status };
      } else {
        return { status: 500 }; 
      }
    }
}

export async function getSolutions(){
    try {
        const response = await axios.get(API + '/solution');
        if (response.status != 200) {
            return {
                status: response.status,
                data: "Error"
            }
        }

        return {
            status: 200,
            data: response.data
        }
    } catch (e: any) {
        console.log(e)
        return {
            status: e.response.status,
            data: ""
        }
    }

}

export async function getJobFunctions(solution: string, jobRole: string){
    try {
        const response = await axios.get(API + `/solution/${jobRole}/${solution}`, {
        
        });
        if (response.status != 200) {
            return {
                status: response.status,
                data: "Error"
            }
        }

        return {
            status: 200,
            data: response.data
        }
    } catch (e: any) {
        console.log(e)
        return {
            status: e.response.status,
            data: ""
        }
    }
}

export async function submitFinalForm(finalData: SummaryDetails) {
    try {
        console.log(finalData)
        const response = await axios.post(API + '/submit-form', 
        finalData
        );
        if (response.status != 200) {
            return {
                status: response.status,
                msg: "Error"
            }
        }

        return {
            status: 200,
            msg: response.data
        }
    } catch (e: any) {
        console.log(e)
        return {
            status: e.response.status,
            msg: ""
        }
    }

}


export async function approveEmployee(id: number, token: String) {

    try {
        const response = await axios.post(API + '/employee/approve-employee', {
            id
        }, {
            headers: {
                Authorization: "Bearer " + token}
        })

        if (response.status == 200) {
            return true
        } 

        return false
    } catch(e: any) {
        console.log(e)
    }
}


export async function rejectEmployee(id: number, token: String) {

    try {
        const response = await axios.post(API + '/employee/reject-employee', {
            id
        }, {
            headers: {
                Authorization: "Bearer " + token}
        })

        if (response.status == 200) {
            return true
        } 

        return false
    } catch(e: any) {
        console.log(e)
    }
}


export async function editJobDesciption(id: number,newDescription: String, token: String) {

    try {
        const response = await axios.put(API + `/job-function/${id}`, {
            newDescription
        }, {
            headers: {
                Authorization: "Bearer " + token}
        })

        if (response.status == 200) {
            return true
        } 

        return false
    } catch(e: any) {
        console.log(e)
    }
}


export async function deleteJobDescription(id: number, token: String) {

    try {
        const response = await axios.delete(API + `/job-function/${id}`, {
            headers: {
                Authorization: "Bearer " + token}
        })

        if (response.status == 200) {
            return true
        } 

        return false
    } catch(e: any) {
        console.log(e)
    }
}