import { ChangeEvent, useState } from "react";
import Option from "../lib/Interfaces/CheckboxOptions";
import JobFunction from "../lib/Interfaces/JobFunction";

interface MultiCardWindowInterface {
  options: Option[];
  selectedValues: JobFunction[];
  onChange: (selectedValues: JobFunction[]) => void;
  onOptionsChange: (options: Option[]) => void;
}

const MultiCardWindow: React.FC<MultiCardWindowInterface> = ({
  options,
  selectedValues,
  onChange,
  onOptionsChange,
}) => {
  const [newOption, setNewOption] = useState<string>("");

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewOption(event.target.value);
  };

  const handleAddOption = () => {
    if (newOption.trim() !== "") {
      const newOptionValue = {
        desc: newOption.toLowerCase(),
        new: true,
      };
      const updatedOptions: Option[] = [
        ...options,
        { label: newOption, value: newOptionValue },
      ];
      onOptionsChange(updatedOptions); // Update options state
      const newSelectedValues: JobFunction[] = [
        ...selectedValues,
        newOptionValue,
      ];
      onChange(newSelectedValues);
      setNewOption("");
    }
  };

  const handleChange = (desc: String) => {
    const newSelectedValues = selectedValues.find((sv) => sv.desc == desc) == null
      ? [
          ...selectedValues,
          options.find((option) => option.value.desc == desc)!.value,
        ]
      : selectedValues.filter((val) => val.desc !== desc);

    onChange(newSelectedValues);
  };

  return (
    <>
      <div>
        <p className="font-semibold">Select Job Function(s):</p>
        <div className="h-72 border border-black overflow-auto my-4">
          {options.map((option, index) => {
            return (
              <div
                className={`border-b w-full max-w-full hover:cursor-pointer min-h-10 p-5 text-center break-words  ${selectedValues.find((selectedValue) => selectedValue.desc == option.value.desc) != null ? 'bg-blue-400' : ''}`}
                onClick={() => handleChange(option.value.desc)}
              >
                {option.label}
              </div>
            );
          })}
        </div>
        <div className="items-centerflex-row flex gap-3">
          <input
            type="text"
            value={newOption}
            onChange={handleOptionChange}
            className="form-input border border-black p-2 flex-grow"
            placeholder="Add new option"
          />
          <button
            type="button"
            onClick={handleAddOption}
            className="bg-green-500 hover:bg-green-700 py-2 px-4 rounded text-white"
          >
            Add
          </button>
        </div>
      </div>
    </>
  );
};

export default MultiCardWindow;
