import React from "react";

interface InputFieldProps {
  id: string;
  name: string;
  type: string;
  placeholder: string;
  value: any;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onBlurCapture?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputField: React.FC<InputFieldProps> = ({
  id,
  name,
  type,
  placeholder,
  onChange,
  value,
  onBlur,
  onBlurCapture,
}) => {
  const inputClassName = `${
    type === "number" ? "w-1/4" : "w-full"
  } bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3 focus:outline-none focus:bg-white focus:border-gray-500`;

  return (
    <div className="relative my-5">
      <input
        id={id}
        name={name}
        type={type}
        placeholder=" "
        className={`block w-full px-2.5 pb-2.5 pt-4 bg-transparent border-2 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:border-blue-600 peer ${inputClassName}`}
        onChange={onChange}
        value={value}
        onWheel={(event) => event.currentTarget.blur()}
        onBlur={onBlur}
        onBlurCapture={onBlurCapture}
      />
      <label
        htmlFor={id}
        className="absolute text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 left-1 z-10 origin-[0] bg-white px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
      >
        {placeholder}
      </label>
    </div>
  );
};

export default InputField;
