import React from "react";

interface DropdownFieldProps {
  id: string;
  name: string;
  label: string;
  options: string[];
  placeholder: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
}

const DropdownField: React.FC<DropdownFieldProps> = ({
  id,
  name,
  label,
  options,
  placeholder,
  onChange,
  value,
}) => {
  return (
    <div className="my-5">
      <label
        className="block tracking-wide mb-2"
        htmlFor={id}
      >
        {label}
      </label>
      <select
        id={id}
        name={name}
        className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3 focus:outline-none focus:bg-white focus:border-gray-500"
        onChange={onChange}
        value={value}
        required
      >
        <option value="" disabled selected hidden>
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropdownField;
