import { useParams } from "react-router-dom";
import AdminLayout from "../components/AdminLayout";
import AppColors from "../styles/colors";
import { Button, InputLabel, MenuItem, Select } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  deleteJobDescription,
  editJobDesciption,
  getSolutionProviderInfo,
  getSolutionProvidersForJobRole,
  getSpecificJobRoles,
} from "../api/API";
import { LoginTokenContext } from "../contexts/LoginTokenContext";
import TwoToggleButton from "../components/TwoToggleButton";
import * as XLSX from "xlsx";
import JobDescriptionCard from "../components/JobDescriptionCard";

interface JobRoleProps {}

const JobRole: React.FC<JobRoleProps> = ({}) => {
  const params = useParams();
  const [token, setToken, encodedToken, setEncodedToken] =
    useContext(LoginTokenContext);
  const [selectedSolutionProvider, setSelectedSolutionProvider] =
    useState("All");
  const [solutionProviderInfo, setSolutionProviderInfo] = useState<any>(null);
  const [selectedSolutionProviderInfo, setSelectedSolutionProviderInfo] =
    useState<any>(null);
  const [infoLoading, setInfoLoading] = useState(true);
  const [solutionProviders, setSolutionProviders] = useState<any[]>([]);
  const [beforeView, setBeforeView] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const solutionProvidersResponse = await getSpecificJobRoles(
        params.jobRole ?? "",
        token
      );

      if (solutionProvidersResponse.status == 200) {
        setSolutionProviders(
          extractSolutionProviders(solutionProvidersResponse.data)
        );
        setSelectedSolutionProviderInfo(solutionProvidersResponse.data);
        setSolutionProviderInfo(solutionProvidersResponse.data);
      } else {
        //Enqueue snackbar
      }
    }
    fetchData();
  }, []);

  const convertDataToWorkbook = (data: any) => {
    const worksheetData = data.map((item: any) => ({
      Description: item.desc,
      "Solution Provider": item.job_mapping.solution_provider.name,
      "Before/After": item.before ? "Before" : "After",
    }));

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, params.jobRole);

    return workbook;
  };

  const handleDownload = () => {
    const workbook = convertDataToWorkbook(solutionProviderInfo);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileDownloadUrl = URL.createObjectURL(data);

    const downloadLink = document.createElement("a");
    downloadLink.href = fileDownloadUrl;
    downloadLink.setAttribute("download", `${params.jobRole}.xlsx`);
    downloadLink.click();
  };

  function extractSolutionProviders(jobFunctions: any[]) {
    let solutionProviders = new Set();
    jobFunctions.forEach((jf) => {
      solutionProviders.add(jf.job_mapping.solution.name);
    });
    const result = Array.from(solutionProviders);
    console.log(result);
    return result;
  }

  async function handleChangeSolutionProvider(event: any) {
    const solProv = event.target.value;
    if (solProv == "All") {
      console.log("Fetch all");
      setSelectedSolutionProviderInfo(solutionProviderInfo);
    } else {
      //Fetch solProv info
      setSelectedSolutionProvider(solProv);
      setSelectedSolutionProviderInfo((prev: any) => {
        return prev.filter(
          (spi: any) => spi.job_mapping.solution.name == solProv
        );
      });
    }
  }

  async function handleEditJobDescription(newJobDesc: String, id: number) {
    try {
      await editJobDesciption(id, newJobDesc, token)
      setSolutionProviderInfo((prev:any) => {
        return prev.map((spi:any) => {
          if (spi.id == id) {
            spi.desc = newJobDesc
          }
          return spi
        })
      })
    } catch (e) {
      console.log(e)
    }
  }

  async function handleDeleteJobDescription(id: number) {
    try{
      await deleteJobDescription(id, token)
      setSolutionProviderInfo((prev:any) => {
        return prev.filter((spi:any) => spi.id != id)
      })
    } catch (e) {
      console.log(e)
    }
  }


  return (
    <>
      <AdminLayout selected={"Editor"}>
        <div
          style={{
            height: "calc(100vh - 60px)",
            minHeight: "min-content",
            minWidth: "min-content",
          }}
          className="p-6 flex flex-col"
        >
          <div className="flex-1">
            <h1 className="text-2xl xl:text-4xl font-bold">Editor</h1>
            <div className="my-6">
              <h1
                className="text-xl xl:text-2xl font-bold mb-4"
                style={{
                  color: AppColors.ThemePurple,
                }}
              >
                {params.jobRole}
              </h1>
              <div className="flex flex-row justify-between">
                <div className="flex flex-col items-center justify-center min-w-64 xl:min-w-96">
                  <p className="text-left font-bold w-full">
                    Solution
                  </p>
                  <Select
                    value={selectedSolutionProvider}
                    className="w-full h-12 bg-white"
                    onChange={handleChangeSolutionProvider}
                  >
                    <MenuItem key={"all"} value={"All"}>
                      All
                    </MenuItem>
                    {solutionProviders.map((sol: any) => {
                      return (
                        <MenuItem key={sol} value={sol}>
                          {sol}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
                <Button
                  color="info"
                  variant="outlined"
                  onClick={handleDownload}
                >
                  Download
                </Button>
              </div>
              <TwoToggleButton
                viewEnabled={beforeView}
                enableView={() => setBeforeView(true)}
                disableView={() => setBeforeView(false)}
                enableViewText="Before"
                disableViewText="After"
              />
            </div>
          </div>
          <div id="desc content" className="m-0 overflow-auto flex-[2_2_0%]">
            <div>
              <div
                style={{
                  borderBottomWidth: "1px",
                }}
                className="p-3 w-full flex flex-row mx-auto border-b-black bg-white"
              >
                <div className=" w-3/5 font-bold">Description</div>
                <div className="w-1/5 flex flex-col items-center justify-center font-bold">
                  Solution
                </div>
                <div className="w-1/5"></div>
              </div>
            </div>
            {solutionProviderInfo &&
              beforeView &&
              solutionProviderInfo.map((spi: any) => {
                if (spi.before) {
                  return (
                    <JobDescriptionCard
                      id={spi.id}
                      initDescription={spi.desc}
                      solutionName={spi.job_mapping.solution.name}
                      handleConfirmDelete={handleDeleteJobDescription}
                      handleConfirmEdit={handleEditJobDescription}
                    />
                  );
                }
                return <></>;
              })}
            {solutionProviderInfo && !beforeView && (
              <>
                {solutionProviderInfo.map((spi: any) => {
                  if (!spi.before) {
                    return (
                      <JobDescriptionCard
                        id={spi.id}
                        initDescription={spi.desc}
                        solutionName={spi.job_mapping.solution.name}
                        handleConfirmDelete={handleDeleteJobDescription}
                        handleConfirmEdit={handleEditJobDescription}
                      />
                    );
                  }
                  return <></>;
                })}
              </>
            )}
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default JobRole;
