import { ArrowForward } from "@mui/icons-material";
import { useContext, useState } from "react";
import { approveEmployee, rejectEmployee } from "../api/API";
import { Button } from "@mui/material";
import { LoginTokenContext } from "../contexts/LoginTokenContext";

interface EmployeeDetailCardProps {
  employeeDetails: any;
}

const EmployeeDetailCard: React.FC<EmployeeDetailCardProps> = ({
  employeeDetails,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [token, setToken, encodedToken, setEncodedToken] =
    useContext(LoginTokenContext);
  return (
    <>
      <div className="border-b border-black py-4">
        <div
          className="flex flex-row justify-between items-baseline hover:cursor-pointer"
          onClick={() => {
            setExpanded((prev) => !prev);
          }}
        >
          <p className={`text-xl ${expanded && "font-bold"}`}>
            {employeeDetails.name}
          </p>
          <div
            className={`${
              (employeeDetails.isApproved == null && "bg-gray-400") ||
              (employeeDetails.isApproved && "bg-green-400") ||
              (!employeeDetails.isApproved && "bg-red-600")
            } 
              p-2 w-48 text-center font-bold text-white`}
          >
            {(employeeDetails.isApproved == null && "PENDING") ||
              (employeeDetails.isApproved && "APPROVED") ||
              (!employeeDetails.isApproved && "REJECTED")}
          </div>
        </div>
        <div
          className={`overflow-hidden transition-max-h duration-500 ease-in-out ${
            expanded ? "max-h-screen" : "max-h-0"
          }`}
        >
          <div className="my-4">
            <div className="flex flex-row justify-between">
              <div className="grid grid-cols-2 gap-x-36 gap-y-2">
                <div>
                  <h2 className="font-bold">Citizenship</h2>
                  <p>{employeeDetails.citizenship}</p>
                </div>
                <div>
                  <h2 className="font-bold">DOH</h2>
                  <p>{employeeDetails.date_of_hire}</p>
                </div>
                <div>
                  <h2 className="font-bold">Highest Qualification</h2>
                  <p>{employeeDetails.highest_qualification}</p>
                </div>
              </div>
              {employeeDetails.isApproved == null && (
                <div className="flex flex-row gap-3 max-w-48">
                  <Button
                    variant="contained"
                    className="h-min"
                    color="success"
                    onClick={async () => {
                      const success = await approveEmployee(
                        employeeDetails.id,
                        token
                      );
                      if (!success) {
                        //Enqueue message
                        console.log("Failed to approve employee");
                      } else {
                        window.location.reload();
                      }
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    className="h-min"
                    color="error"
                    onClick={async () => {
                      const success = await rejectEmployee(
                        employeeDetails.id,
                        token
                      );
                      if (!success) {
                        //Enqueue message
                        console.log("Failed to reject employee");
                      } else {
                        window.location.reload();
                      }
                    }}
                  >
                    Reject
                  </Button>
                </div>
              )}
            </div>
            <div className="border border-black p-4 py-1 mt-4">
              <div className="my-2">
                <h1 className="font-bold mb-1">Department</h1>
                <p className="w-64 justify-between flex">
                  {employeeDetails.b_department}
                  <ArrowForward />
                  {employeeDetails.a_department}
                </p>
              </div>
              <div className="my-2">
                <h1 className="font-bold mb-1">Designation</h1>
                <p className="w-64  justify-between flex">
                  {employeeDetails.b_designation}
                  <ArrowForward />
                  {employeeDetails.a_designation}
                </p>
              </div>
              <h1 className="font-bold mb-1">Salary</h1>
              <p className="w-64  justify-between flex">
                {employeeDetails.b_salary}
                <ArrowForward />
                {employeeDetails.a_salary}
              </p>
              <div className="my-2">
                <h1 className="font-bold mb-1">Job Functions</h1>
                <div className="flex flex-row justify-between">
                  <div className="flex-1 w-min">
                    {employeeDetails.job_functions.map((jf: any) => {
                      return jf.before ? <p>• {jf.desc}</p> : <></>;
                    })}
                  </div>
                  <ArrowForward className="flex-1" />
                  <div className="flex-1 w-min">
                    {employeeDetails.job_functions.map((jf: any) => {
                      return !jf.before ? (
                        <>
                          <p>• {jf.desc}</p>
                          {/* <div className="border border-solid border-black">
                            <h2>Task Elements</h2>
                            {jf.task_elements.map((te: any) => {
                              return (
                                <>
                                  <p>- {te.desc}</p>
                                  <p>
                                    Total Training Hours: {te.training_hours}
                                  </p>
                                </>
                              );
                            })}
                          </div> */}
                        </>
                      ) : (
                        <></>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeDetailCard;
