import { Button } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AppColors from "../styles/colors";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSubmissionSummaries } from "../api/API";
import { LoginTokenContext } from "../contexts/LoginTokenContext";

interface SubmissionContentProps {}

const SubmissionContent: React.FC<SubmissionContentProps> = ({}) => {
  const [unreviewedView, setUnreviewedView] = useState(true);
  const [submissions, setSubmissions] = useState<any[]>([]);
  const navigate = useNavigate();
  const [token, setToken, encodedToken, setEncodedToken] =
    useContext(LoginTokenContext);
  
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "company_name",
      headerName: "Company",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "company_uen",
      headerName: "Company UEN",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "submission_date",
      headerName: "Submission Date",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "tac",
      headerName: "TAC",
      minWidth: 100,
      flex: 1,
      valueGetter: (x:any) => {
        if (x) {
          return x.org.toUpperCase()
        }
        return ""
      }
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 180,
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="text-center">
            <Button
              className="underline"
              onClick={() => {
                console.log("Navigate to company id " + params.id);
                navigate("/company/" + params.id);
              }}
            >
              View
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    async function fetchData() {
      const submissionSummaries = await getSubmissionSummaries(token);
      if (submissionSummaries.status == 200) {
        setSubmissions(submissionSummaries.data);
      } else {
        //Enqueue snackbar
      }
    }
    fetchData();
  }, []);
  return (
    <>
      <div className="my-4 flex-row flex">
        <div
          style={{
            backgroundColor: unreviewedView ? AppColors.ThemePurple : undefined,
          }}
          className={`cursor-pointer xl:text-xl mr-6 p-2 ${
            unreviewedView ? "rounded-md text-white" : "underline"
          }`}
          onClick={() => {
            setUnreviewedView(true);
          }}
        >
          UNREVIEWED
        </div>
        <div
          style={{
            backgroundColor: unreviewedView ? undefined : AppColors.ThemePurple,
          }}
          className={`cursor-pointer xl:text-xl p-2 ${
            unreviewedView ? "underline" : "rounded-md text-white"
          }`}
          onClick={() => {
            setUnreviewedView(false);
          }}
        >
          REVIEWED
        </div>
      </div>
      <div>
        <DataGrid
          columns={columns}
          rows={
            unreviewedView
              ? submissions.filter((submission) => !submission.reviewed)
              : submissions.filter((submission) => submission.reviewed)
          }
          style={{
            backgroundColor: "white",
          }}
        />
      </div>
    </>
  );
};

export default SubmissionContent;
