import { Button } from "@mui/material";
import { useState } from "react";
import TextAreaField from "./FormTextArea";

interface JobDescriptionCardProps {
  solutionName: string;
  initDescription: string;
  handleConfirmEdit: Function;
  handleConfirmDelete: Function;
  id: number
}

const JobDescriptionCard: React.FC<JobDescriptionCardProps> = ({
  solutionName,
  initDescription,
  handleConfirmEdit,
  handleConfirmDelete,
  id
}) => {
  const [deleteMode, handleDeleteMode] = useState(false);
  const [editMode, handleEditMode] = useState(false);
  const [description, handleDescription] = useState(initDescription);
  return (
    <div
      style={{
        borderBottomWidth: "1px",
      }}
      className="p-3 w-full flex flex-row mx-auto border-b-black bg-white"
      key={id}
    >
      <div className=" w-3/5">
        {editMode ? (
          <TextAreaField
            id="description"
            name="description"
            placeholder=""
            value={description}
            onChange={(e) => handleDescription(e.target.value)}
          />
        ) : (
          <>{description}</>
        )}
      </div>
      <div className="w-1/5 flex flex-col items-center justify-center">
        {solutionName}
      </div>
      <div className="w-1/5 flex flex-row justify-center">
        {deleteMode && (
          <>
            <Button
              onClick={async () => {
                await handleConfirmDelete(id);
                handleDeleteMode(false);
              }}
            >
              Confirm
            </Button>
            <Button onClick={() => {handleDeleteMode(false)}}>Cancel</Button>
          </>
        )}
        {editMode && (
          <>
            <Button
              onClick={async () => {
                await handleConfirmEdit(description, id);
                handleEditMode(false);
              }}
            >
              Confirm
            </Button>
            <Button onClick={() => {handleEditMode(false)}}>Cancel</Button>
          </>
        )}
        {!editMode && !deleteMode && (
          <>
            <Button
              onClick={() => {
                handleEditMode(true);
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                handleDeleteMode(true);
              }}
            >
              Delete
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default JobDescriptionCard;
