import React, { useState, useEffect } from "react";
import InputField from "./FormInputFields";
import CompanyDetailsFields from "../lib/Interfaces/CompanyDetailsFields";

interface CompanyDetailsFormProps {
  handleNextPageButton: (data: CompanyDetailsFields) => void;
  initialCompanyData?: CompanyDetailsFields | null;
}

const CompanyDetailsForm: React.FC<CompanyDetailsFormProps> = ({
  handleNextPageButton,
  initialCompanyData,
}) => {
  const [companyDetailsData, setCompanyDetailsData] =
    useState<CompanyDetailsFields>({
      id: 0,
      company_name: "",
      company_uen: "",
      total_headcount: 0,
      no_singaporecitizen_pr: 0,
      file_name: "",
    });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;

    if (name === "file_name" && files && files.length > 0) {
      const file = files[0];
      setSelectedFile(file);
      setFileName(file.name);
      setCompanyDetailsData((prev) => ({ ...prev, [name]: file.name }));
    } else {
      setCompanyDetailsData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleNextPageButton(companyDetailsData);
  };

  useEffect(() => {
    if (initialCompanyData) {
      setCompanyDetailsData((prevData) => ({
        ...prevData,
        ...initialCompanyData,
      }));
      if (initialCompanyData.file_name) {
        setFileName(initialCompanyData.file_name);
      }
    }
  }, [initialCompanyData]);

  return (
    <div id="Company_Details" className="py-6 px-8 mt-6 lg:mt-0">
      <h2 className="font-bold break-normal text-2xl lg:text-4xl mb-6">
        Company Details
      </h2>
      <form className="w-full p-10 px-0" onSubmit={handleSubmit}>
        <p className="text-lg font-medium pb-1">Company Information</p>
        <InputField
          id="name"
          name="company_name"
          type="text"
          placeholder="Company Name"
          value={companyDetailsData.company_name}
          onChange={handleChange}
        />
        <InputField
          id="uen"
          name="company_uen"
          type="text"
          placeholder="Company UEN"
          value={companyDetailsData.company_uen}
          onChange={handleChange}
        />

        <div className="flex justify-end mt-4">
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-300 text-white font-bold py-5 px-7 rounded text-xl"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default CompanyDetailsForm;
