import styled, { css, keyframes } from "styled-components";
import AppColors from "./colors";

const slide = keyframes`
  0% {
    transform: translateX(150%); /* Start from the left edge */
  }
  100% {
    transform: translateX(300%); /* Move to the right edge */
  }
`;

const sharedFlexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const sharedFlexSpaceBetween = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const sharedButtonStyle = css`
  background: ${AppColors.ThemeLightPurple};
  padding: 1rem;
`;

const vesselSearchButtonStyle = css`
  width: 8rem;
  background: black;
  padding: 1rem;
  &:hover {
    background: grey;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 17rem;
  padding: 1rem 0rem;
  align-items: center;
  animation: ${slide} 5s linear infinite alternate both running;
`;

const Image = styled.img`
  /* Default styles */
  width: 40%;
  height: 20%;

  /* Media query for small screens */
  @media screen and (min-width: 768px) {
    width: 20%;
    height: 10%;
  }
`;

export const Section = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: auto 0;
  padding: 0;
  background: linear-gradient(to bottom, #000000, #000000);
`;

export {
  sharedFlexCenter,
  sharedFlexSpaceBetween,
  sharedButtonStyle,
  Image,
  vesselSearchButtonStyle,
};
