import React, { useState, useEffect } from "react";
import DropdownField from "./FormDropDownFields";
import InputField from "./FormInputFields";
import ReskillingDetailsFields from "../lib/Interfaces/ReskillingDetailsFields";
import TextAreaField from "./FormTextArea";
import { getSolutions } from "../api/API";

interface ReskillingDetailsFormProps {
  handleNextPageButton: (data: ReskillingDetailsFields) => void;
  handlePrevPageButton: () => void;
  setAffectedWorkersNum: (num: number) => void;
  handleSelectedSolution: (sol: string) => void;
  affectedWorkers: Number;
  initialReskillData?: ReskillingDetailsFields | null;
}

const ReskillingDetailsForm: React.FC<ReskillingDetailsFormProps> = ({
  handleNextPageButton,
  handlePrevPageButton,
  setAffectedWorkersNum,
  affectedWorkers,
  initialReskillData,
  handleSelectedSolution,
}) => {
  useEffect(() => {
    async function fetchSolutions() {
      const solutions = await getSolutions();
      if (solutions.status == 200) {
        setSolutions(solutions.data);
      } else {
        //Enqueue error
      }
    }
    fetchSolutions();
  }, []);

  const handleAffectedWorkersNum = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAffectedWorkersNum(Number(event.target.value));
  };
  const [solutions, setSolutions] = useState([]);
  const [reskillDetailsData, setReskillDetailsData] =
    useState<ReskillingDetailsFields>({
      id: 0,
      solution: "",
      solution_provider: "",
      sp_company_uen: "",
      department_of_locals: "",
      sp_company_details: "",
      details_of_business: "",
      project_duration: 0,
      project_duration_unit: "",
      no_of_workers_affected: 0,
      government_fund: "",
      other_government_fund_details: "",
      poc_contact_name: "",
      poc_contact_designation: "",
      poc_contact_number: "",
      poc_contact_email: "",
    });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReskillDetailsData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setReskillDetailsData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReskillDetailsData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleNextPageButton(reskillDetailsData);
  };

  useEffect(() => {
    if (initialReskillData) {
      setReskillDetailsData((prevData) => ({
        ...prevData,
        ...initialReskillData,
      }));
      console.log("initial data reskill", initialReskillData);
    }
  }, [initialReskillData]);

  return (
    <div id="Reskilling_Details" className="py-6 px-8 mt-6 lg:mt-0">
      <h2 className="font-bold break-normal text-2xl lg:text-4xl mb-6">
        Reskilling Details
      </h2>
      <form className="w-full p-10 px-0" onSubmit={handleSubmit}>
        <p className="text-lg font-medium pb-1">Project Information</p>
        <DropdownField
          id="solution"
          name="solution"
          label="Solution"
          placeholder="Please select a Solution"
          options={solutions}
          onChange={(e) => {
            handleSelectedSolution(e.target.value);
            handleDropdownChange(e);
          }}
          value={reskillDetailsData.solution}
        />
        <InputField
          id="solutionProvider"
          name="solution_provider"
          type="text"
          placeholder="Solution Provider"
          value={reskillDetailsData.solution_provider}
          onChange={handleChange}
        />
        <InputField
          id="uen"
          name="sp_company_uen"
          type="text"
          placeholder="Solution provider UEN"
          value={reskillDetailsData.sp_company_uen}
          onChange={handleChange}
        />
        <TextAreaField
          id="spCompanyDetails"
          name="sp_company_details"
          placeholder="Solution Provider description and key business areas"
          value={reskillDetailsData.sp_company_details}
          onChange={handleTextAreaChange}
        />
        <InputField
          id="departmentDetails"
          name="department_of_locals"
          type="text"
          placeholder="Departments/job roles in which locals are employed in company"
          value={reskillDetailsData.department_of_locals}
          onChange={handleChange}
        />

        <TextAreaField
          id="transformationDetails"
          name="details_of_business"
          placeholder="Details of business transformation (background, reason) and workforce transformation (how does the project translate to workforce reskilling)"
          value={reskillDetailsData.details_of_business}
          onChange={handleTextAreaChange}
        />
        <p className="text-lg font-medium pb-1">
          Job Redesign Reskilling (JRR) On-Job-Training Period
        </p>
        <InputField
          id="projectDuration"
          name="project_duration"
          type="number"
          placeholder="Project duration (Months)"
          value={reskillDetailsData.project_duration}
          onChange={handleChange}
        />
        {/* <DropdownField
              id="projectDurationUnit"
              name="project_duration_unit"
              label="Project Duration"
              placeholder="Project Duration"
              options={["Days", "Months", "Years"]}
              onChange={handleDropdownChange}
              value={reskillDetailsData.project_duration_unit}
            /> */}

        <input
          type="text"
          defaultValue="Months"
          value={reskillDetailsData.project_duration_unit}
          readOnly
          hidden
          className="p-2 border rounded w-[50%] mr-2 text-white"
        />
        <InputField
          id="workersAffected"
          name="no_of_workers_affected"
          type="number"
          placeholder="Number of SC/PR employees to undergo JRR"
          onChange={(e) => {
            handleAffectedWorkersNum(e);
            handleChange(e);
          }}
          value={reskillDetailsData.no_of_workers_affected}
        />
        <DropdownField
          id="governmentFunding"
          name="government_fund"
          label="Are you tapping on any other government funding?"
          placeholder="Are you tapping on any other government funding?"
          options={["Yes", "No"]}
          onChange={handleDropdownChange}
          value={reskillDetailsData.government_fund}
        />
        {reskillDetailsData.government_fund === "Yes" && (
          <InputField
            id="governmentFundDetails"
            type="text"
            name="other_government_fund_details"
            value={reskillDetailsData.other_government_fund_details}
            onChange={handleChange}
            placeholder="Please specify other government funding"
          />
        )}
        <p className="text-lg font-medium pb-1">Point of Contact Information</p>
        <InputField
          id="contactName"
          name="poc_contact_name"
          type="text"
          placeholder="Contact Name"
          value={reskillDetailsData.poc_contact_name}
          onChange={handleChange}
        />
        <InputField
          id="designation"
          name="poc_contact_designation"
          type="text"
          placeholder="Designation"
          value={reskillDetailsData.poc_contact_designation}
          onChange={handleChange}
        />
        <InputField
          id="contactNumber"
          name="poc_contact_number"
          type="text"
          placeholder="Contact Number"
          value={reskillDetailsData.poc_contact_number}
          onChange={handleChange}
        />
        <InputField
          id="contactEmail"
          name="poc_contact_email"
          type="text"
          placeholder="Contact Email"
          value={reskillDetailsData.poc_contact_email}
          onChange={handleChange}
        />
        <div className="flex justify-between mt-4">
          <button
            type="button"
            className="bg-gray-500 hover:bg-gray-300 text-white font-bold py-2 px-4 rounded text-xl"
            onClick={handlePrevPageButton}
          >
            Previous
          </button>
          <button className="bg-green-500 hover:bg-green-300 text-white font-bold py-5 px-7 rounded text-xl">
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default ReskillingDetailsForm;
