import { useParams } from "react-router-dom";
import AdminLayout from "../components/AdminLayout";
import FormInfoDisplayCard from "../components/FormInfoDisplayCard";
import styled from "styled-components";
import AppColors from "../styles/colors";
import {
  ArrowForward,
  Cancel,
  CheckCircle,
  CorporateFare,
  Person,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { approveEmployee, getFormDetails, rejectEmployee } from "../api/API";
import { LoginTokenContext } from "../contexts/LoginTokenContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import EmployeeDetailCard from "../components/EmployeeDetailCard";

interface CompanyProps {}

const InfoTag = styled.p({
  margin: "4px 0 4px 0",
});

const Company: React.FC<CompanyProps> = ({}) => {
  const params = useParams();
  const [token, setToken, encodedToken, setEncodedToken] =
    useContext(LoginTokenContext);

  const [companyDetails, setCompanyDetails] = useState<any>();

  useEffect(() => {
    async function fetchData() {
      const formDetailsResponse = await getFormDetails(
        Number(params.id),
        token
      );

      if (formDetailsResponse.status == 200) {
        console.log(formDetailsResponse.data);
        setCompanyDetails(formDetailsResponse.data);
      } else {
        //Enqueue Snackbar
      }
    }

    fetchData();
  }, []);

  function checkReviewed(companyDetails: any) {
    let reviewed = true;
    companyDetails.employees.forEach((emp: any) => {
      if (emp.isApproved == null) {
        reviewed = false;
      }
    });
    return reviewed;
  }

  return (
    <>
      <AdminLayout selected={"Submissions"}>
        {companyDetails ? (
          <div
            style={{
              height: "calc(100vh - 60px)",
              minHeight: "min-content",
              minWidth: "min-content",
            }}
            className="p-6 flex flex-col"
          >
            <h1 className="text-2xl xl:text-4xl font-bold pl-9 mb-9">
              Approval
            </h1>
            <div className="p-9">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                  <h1 className="mr-1 xl:mr-3 text-2xl xl:text-3xl font-bold">
                    {companyDetails.company_name}
                  </h1>
                  <h1 className="text-2xl xl:text-3xl font-bold">
                    (UEN: {companyDetails.company_uen})
                  </h1>
                </div>
                <div>
                  {companyDetails.reviewed || checkReviewed(companyDetails) ? (
                    <p
                      style={{
                        backgroundColor: AppColors.ThemeLightGreen,
                      }}
                      className="p-2 rounded-md font-bold text-white"
                    >
                      REVIEWED
                    </p>
                  ) : (
                    <p
                      style={{
                        backgroundColor: AppColors.ThemeLightRed,
                      }}
                      className="p-2 rounded-md font-bold text-white"
                    >
                      UNREVIEWED
                    </p>
                  )}
                </div>
              </div>
              <p className=" xl:text-xl">
                Submitted on: {companyDetails.submission_date}
              </p>
              <div className="flex-1 max-w-96 lg:flex-shrink p-2 pl-0 bg-white">
                <h2 className="font-bold">Point of Contact</h2>
                <p>
                  {companyDetails.applyee.name} (
                  {companyDetails.applyee.designation})
                </p>
                <p>p: {companyDetails.applyee.contact_num}</p>
                <p>e: {companyDetails.applyee.email}</p>
              </div>
              <div className="my-6 xl:mt-10">
                <h1 className="text-xl xl:text-2xl font-bold mb-2">
                  Business and Workforce Transformation Details
                </h1>
                <p>{companyDetails.details_of_business}</p>
              </div>
              <div className="my-6">
                <h1 className="text-xl xl:text-2xl font-bold mb-2">
                  Project Duration
                </h1>
                <p>{companyDetails.project_duration} Month(s)</p>
              </div>
              <div className="my-6">
                <h1 className="text-xl xl:text-2xl font-bold mb-2">
                  Other Grant
                </h1>
                <p>{companyDetails.other_grants}</p>
              </div>
              <div className="my-6">
                <h1 className="text-xl xl:text-2xl font-bold mb-4">
                  Employee Details
                </h1>
                <hr className="w-full border-black sticky top-0" />
                {companyDetails.employees.map((employee: any) => {
                  return <EmployeeDetailCard employeeDetails={employee} />;
                })}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </AdminLayout>
    </>
  );
};

export default Company;
