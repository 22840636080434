import AppColors from "../styles/colors";

interface TwoToggleButtonProps {
  viewEnabled: boolean;
  enableView: Function;
  disableView: Function;
  enableViewText: String;
  disableViewText: String;
}

const TwoToggleButton: React.FC<TwoToggleButtonProps> = ({
  viewEnabled,
  enableView,
  disableView,
  enableViewText,
  disableViewText,
}) => {
  return (
    <>
      <div className="my-4 flex-row flex">
        <div
          style={{
            backgroundColor: viewEnabled ? AppColors.ThemePurple : undefined,
          }}
          className={`cursor-pointer xl:text-xl mr-6 p-2 ${
            viewEnabled ? "rounded-md text-white" : "underline"
          }`}
          onClick={() => {
            enableView();
          }}
        >
          {enableViewText}
        </div>
        <div
          style={{
            backgroundColor: viewEnabled ? undefined : AppColors.ThemePurple,
          }}
          className={`cursor-pointer xl:text-xl p-2 ${
            viewEnabled ? "underline" : "rounded-md text-white"
          }`}
          onClick={() => {
            disableView();
          }}
        >
          {disableViewText}
        </div>
      </div>
    </>
  );
};

export default TwoToggleButton;
