import { Logout } from "@mui/icons-material";
import AppColors from "../styles/colors";
import AdminSidebar from "./AdminSidebar";
import { IconButton } from "@mui/material";

interface AdminLayoutProps {
  children?: React.ReactNode;
  selected: String;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children, selected }) => {
  return (
    <div
      style={{
        backgroundColor: AppColors.ThemeLightGrey,
      }}
      className="h-max min-h-screen flex flex-row py-6 px-6"
    >
      <div className="w-1/5 px-6">
        <AdminSidebar selected={selected} />
      </div>
      <div
        className="w-4/5 max-h-screen overflow-auto xl:px-36 bg-white rounded-md"
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "white " + AppColors.ThemeLightGrey,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default AdminLayout;
