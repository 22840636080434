import { CheckCircle } from "@mui/icons-material";
import Navbar from "../components/Navbar";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "../lib/constants";

interface SuccessProps {}

const Success: React.FC<SuccessProps> = ({}) => {
  const navbarRef = useRef<HTMLDivElement>(null);
  const {association} = useParams()
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-black text-gray-100 tracking-wider leading-normal h-screen">
        <Navbar navbarRef={navbarRef} />
        <div className="container mx-auto px-2 h-full flex flex-col items-center justify-center">
          <div
            id="Success"
            className="py-6 px-8 lg:mt-0 rounded shadow bg-gray-900 text-center w-max h-max"
            onClick={() => {
              navigate('/form/' + association);
            }}
          >
            <CheckCircle
              htmlColor="green"
              className="my-2"
              sx={{
                fontSize: "10em",
              }}
            />
            <h1 className=" text-green-600 text-3xl xl:text-6xl">Success!</h1>
            <p className="text-white">Thank you for submitting!</p>
            <p className="text-white">We have sent you a confirmation email.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Success;
