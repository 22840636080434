import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { SnackbarProvider, useSnackbar } from "notistack";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <SnackbarProvider
    anchorOrigin={{
      horizontal: "center",
      vertical: "top",
    }}
    autoHideDuration={5000}
  >
    <App />
  </SnackbarProvider>
);
