import React, { useContext, useEffect, useState } from "react";
import AdminLayout from "../components/AdminLayout";
import { Button } from "@mui/material";
import AppColors from "../styles/colors";
import { getSubmissionCount } from "../api/API";
import { LoginTokenContext } from "../contexts/LoginTokenContext";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../lib/constants";

interface AdminDashboardProps {}

const AdminDashboard: React.FC<AdminDashboardProps> = ({}) => {
  const [token, setToken, encodedToken, setEncodedToken] =
    useContext(LoginTokenContext);
  const [submissionCount, setSubmissionCount] = useState();
  useEffect(() => {
    async function init() {
      const res = await getSubmissionCount(token);
      setSubmissionCount(res.data);
    }

    init();
  }, []);

  const navigate = useNavigate();

  return (
    <AdminLayout selected={"Dashboard"}>
      <div className="p-6">
        <h1 className="text-2xl xl:text-4xl font-bold">Welcome to Dashboard</h1>
        <div className="my-24">
          <div className="mx-auto w-1/2 xl:w-1/3 p-7 rounded-lg border border-black">
            <h2 className="text-base xl:text-xl font-bold">SUBMISSIONS</h2>
            <div className="my-12 text-center">
              <p
                className="text-center text-3xl font-bold"
                style={{
                  color: AppColors.ThemePurple,
                }}
              >
                {submissionCount ?? "Loading..."}
              </p>
              <p>submissions to be reviewed</p>
            </div>
            <div className="text-center">
              <Button
                variant="contained"
                onClick={() => {
                  navigate(AppRoutes.SubmissionPage);
                }}
              >
                View
              </Button>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminDashboard;
