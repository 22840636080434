import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AdminLayout from "../components/AdminLayout";
import AppColors from "../styles/colors";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { getAllJobRoles } from "../api/API";
import { LoginTokenContext } from "../contexts/LoginTokenContext";

interface EditorProps {}

const Editor: React.FC<EditorProps> = () => {
  const [token, setToken, encodedToken, setEncodedToken] =
    useContext(LoginTokenContext);
  const navigate = useNavigate();
  const [jobRoles, setJobRoles] = useState<any>([]);
  const sampleData = [
    {
      id: 742,
      jobRole: "Software Engineer",
    },
    {
      id: 321,
      jobRole: "Logistic Coordinator",
    },
    {
      id: 432,
      jobRole: "Cargo Loader",
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "No.",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "jobRole",
      headerName: "Job Role",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 180,
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="text-center">
            <Button
              className="underline"
              onClick={() => {
                console.log("Navigate to job role " + params.row.jobRole);
                navigate("/editor/" + params.row.jobRole);
              }}
            >
              View
            </Button>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    async function fetchData() {
      let jobRolesResponse = await getAllJobRoles(token);
      if (jobRolesResponse.status == 200) {
        jobRolesResponse = jobRolesResponse.data.map(
          (jr: any, index: number) => {
            return {
              jobRole: jr,
              id: index + 1,
            };
          }
        );
        setJobRoles(jobRolesResponse);
      } else {
        //Enqueue snackbar
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <AdminLayout selected={"Editor"}>
        <div className="p-6">
          <h1 className="text-2xl xl:text-4xl font-bold">Editor</h1>
          <div className="my-12">
            <h1
              className="text-xl xl:text-2xl font-bold mb-4"
              style={{
                color: AppColors.ThemePurple,
              }}
            >
              Job Roles
            </h1>
            <div>
              <DataGrid
                columns={columns}
                rows={jobRoles}
                style={{
                  backgroundColor: "white",
                }}
              />
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default Editor;
