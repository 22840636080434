import { ReactNode, useContext, useEffect, useState } from "react";
import { checkJWT } from "../api/API";
import { LoginTokenContext } from "../contexts/LoginTokenContext";
import { useNavigate } from "react-router-dom";

interface ProtectorProps {
  children: ReactNode;
}

const Protector: React.FC<ProtectorProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<any>(null);
  const [token, setToken, encodedToken, setEncodedToken] =
    useContext(LoginTokenContext);
  const navigate = useNavigate();

  useEffect(() => {
    async function checkJWTValidity() {
      const check_request = await checkJWT(token);
      console.log(check_request.status);
      return check_request.status == 200;
    }
    async function init() {
      const valid = await checkJWTValidity();
      if (!valid) {
        console.log("Invalid");
        navigate("/login");
      }
      setIsAuthenticated(valid);
    }

    init();
  }, []);

  return (
    <>
      {isAuthenticated == null ? (
        <>CONTENT LOADING</>
      ) : isAuthenticated ? (
        children
      ) : (
        <></>
      )}
    </>
  );
};

export default Protector;
