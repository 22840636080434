import AdminLayout from "../components/AdminLayout";
import SubmissionContent from "../components/SubmissionContent";

interface SubmissionProps {}

const Submission: React.FC<SubmissionProps> = () => {
  return (
    <AdminLayout selected={"Submissions"}>
      <div className="p-6">
        <h1 className="text-2xl xl:text-4xl font-bold">Submissions</h1>
        <div className="my-12">
          <SubmissionContent />
        </div>
      </div>
    </AdminLayout>
  );
};

export default Submission;
