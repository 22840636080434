import { Button, TextField } from "@mui/material";
import AppColors from "../styles/colors";
import { useContext, useState } from "react";
import { loginTAC } from "../api/API";
import { LoginTokenContext } from "../contexts/LoginTokenContext";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
const WSGLogo = require("../assets/icons/workforce_sg_logo.png");
const SSALogo = require("../assets/icons/SSA_logo.png");
const SGTDLogo = require("../assets/icons/SGTraDex-logo.png");
interface LoginTACProps {}

const LoginTAC: React.FC<LoginTACProps> = () => {
  const [loginHover, setLoginHover] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken, encodedToken, setEncodedToken] =
    useContext(LoginTokenContext);
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  async function handleLogin() {
    const loginRequest = await loginTAC(email, password);

    if (loginRequest.status == 200) {
      setToken(loginRequest.data.access_token);
      navigate("/dashboard");
    } else {
      //Enqueue snackbar
      enqueueSnackbar("Login credentials failed", { variant: "error" });
    }
  }

  return (
    <div
      className="w-full h-screen mx-auto content-center"
      style={{
        backgroundColor: AppColors.ThemePurple,
      }}
    >
      <div className="w-screen md:w-1/3 md:h-3/5 h-screen mx-auto my-auto rounded-lg py-12 px-20 bg-white items-center justify-center flex">
        <div
          className="h-1/2 flex flex-col"
          style={{
            maxHeight: "540px",
          }}
        >
          <p className="text-2xl font-semibold my-2">Login to TAC Admin Portal</p>
          <div className="flex flex-col text-center w-full mx-auto">
            <TextField
              label="Email"
              variant="filled"
              className="w-full"
              style={{
                margin: "14px 0 7px 0",
              }}
              InputProps={{ disableUnderline: true }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              variant="filled"
              className="w-full"
              style={{
                margin: "7px 0 14px 0",
              }}
              InputProps={{ disableUnderline: true }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex-grow flex flex-col justify-center">
            <Button
              variant="contained"
              style={{
                backgroundColor: loginHover
                  ? AppColors.ThemeBlue
                  : AppColors.ThemeLightGrey,
                color: loginHover ? "white" : "black",
              }}
              onMouseEnter={() => setLoginHover(true)}
              onMouseLeave={() => setLoginHover(false)}
              onClick={() => {
                console.log("Something");
                handleLogin();
              }}
            >
              Log in
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginTAC;
