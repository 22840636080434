import styled from "styled-components";
import {
  Image,
  sharedFlexSpaceBetween,
  Section,
  LogoContainer,
} from "../styles/global";
import { AppRoutes, logos, supportEmail } from "../lib/constants";
import { useNavigate, useParams } from "react-router-dom";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import { useEffect, useRef } from "react";
import AnimationWorkplace from "../assets/AnimationWorkplace.json";
import { motion } from "framer-motion";
import { checkTACExists } from "../api/API";
const WSGLogo = require("../assets/icons/workforce_sg_logo.png");
// const SSALogo = require("../assets/icons/SSA_logo.png");
// const ASMILogo = require("../assets/icons/ASMI_logo.png");
const SGTDLogo = require("../assets/icons/sgtradex_logo.png");
const backgroundImage = require("../assets/BackgroundImage.jpg");

const StyledSection = styled(Section)`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
`;
const ButtonContainer = styled.div`
  margin-top: -200px;
  z-index: 20; /* Adjust the margin-top as needed */
`;
//Home page
function Home() {
  const {association} = useParams()
  const navigate = useNavigate();
  useEffect(() => {
    const checkTAC = async () => {
      const tacExists = await checkTACExists(association ?? "")
      if (tacExists.status != 200) {
        //RenderPageNotFound
      }
      console.log(tacExists)
    }

  }, [])
  const handleNewFormButton = () => {
    // Navigate to the "/form" route
    navigate(`/form/${association}`);
  };

  //Add on to here when need to add TAC
  const getLogo = () => {
    if (association == "asmi") {
      return logos.asmi
    } else if (association == "ssa"){
      return logos.ssa
    }
  }
  const animationRef = useRef<LottieRefCurrentProps>(null);
  const lottieStyle = {
    width: "120%", // Adjust the width as needed
    height: "120%", // Adjust the height as needed
    maxWidth: "500px", // Optional: set a maximum width
    maxHeight: "500px", // Optional: set a maximum height
  };
  return (
    <StyledSection className="flex flex-col justify-between items-center h-screen">
      <div className="bg-black w-full">
        <motion.div
          className="logoMotion"
          initial={{ x: "-90vw" }} // Initial position is outside the left edge of the viewport
          animate={{
            x: "90vw", // Animate to the right edge of the viewport
          }}
          transition={{ duration: 14, repeat: Infinity, ease: "linear" }} // Repeat the animation infinitely
        >
          <div className="flex justify-center items-center space-x-4">
            <Image src={WSGLogo} />
            <Image src={getLogo()} />
            <Image src={SGTDLogo} />
          </div>
        </motion.div>
      </div>
      <div style={lottieStyle}>
        <Lottie animationData={AnimationWorkplace} />
      </div>

      <ButtonContainer>
        <button
          className="bg-green-500 hover:bg-green-300 text-white font-bold py-5 px-7 rounded text-xl z-20"
          onClick={handleNewFormButton}
        >
          Start WSG Application
        </button>
      </ButtonContainer>
      <h1 className="text-sm my-3 text-white">
        Need more information? Contact:{" "}
        <a
          style={{
            height: "1000px",
          }}
          className="underline hover:text-blue-400"
          href={`mailto:${supportEmail}?subject=Support%20required%20for%20Vessel%20Now`}
        >
          {supportEmail}
        </a>
      </h1>
    </StyledSection>
  );
}

export default Home;
