import "./App.css";
import { LoginTokenContext } from "./contexts/LoginTokenContext";
import useToken from "./contexts/useToken";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./lib/constants";
import { ToastContainer } from "react-toastify";
import Home from "./pages/Home";
import Form from "./pages/Form";
import AdminDashboard from "./pages/AdminDashboard";
import Submission from "./pages/Submission";
import Company from "./pages/Company";
import Editor from "./pages/Editor";
import JobRole from "./pages/JobRole";
import LoginWSG from "./pages/LoginWSG";
import Protector from "./components/Protector";
import Success from "./pages/Success";
import LoginTAC from "./pages/LoginTAC";

function App() {
  const { token, setToken, encodedToken, setEncodedToken } = useToken();

  if (!token) {
    return (
      // Login Context ensures that routes/components within the context has access to the tokens for requesting
      <LoginTokenContext.Provider
        value={[token, setToken, encodedToken, setEncodedToken]}
      >
        <BrowserRouter>
          <Routes>
            <Route path={AppRoutes.Home} element={<Home />} />
            <Route path={AppRoutes.Form} element={<Form />} />
            <Route path={AppRoutes.LoginWSG} element={<LoginWSG />} />
            <Route path={AppRoutes.LoginTAC} element={<LoginTAC />} />
            <Route path={AppRoutes.Success} element={<Success />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer position="top-center" />
      </LoginTokenContext.Provider>
    );
  } else {
    //API check to send req to check if valid token

    return (
      // Login Context ensures that routes/components within the context has access to the tokens for requesting
      <LoginTokenContext.Provider
        value={[token, setToken, encodedToken, setEncodedToken]}
      >
        <BrowserRouter>
          <Routes>
            <Route path={AppRoutes.Home} element={<Home />} />
            <Route path={AppRoutes.Form} element={<Form />} />
            <Route
              path={AppRoutes.AdminDashboardPage}
              element={
                <Protector>
                  <AdminDashboard />
                </Protector>
              }
            />
            <Route path={AppRoutes.LoginWSG} element={<LoginWSG />} />
            <Route path={AppRoutes.LoginTAC} element={<LoginTAC />} />
            <Route path={AppRoutes.SubmissionPage} element={<Submission />} />
            <Route path={AppRoutes.CompanyPage} element={<Company />} />
            <Route path={AppRoutes.Editor} element={<Editor />} />
            <Route path={AppRoutes.JobRolePage} element={<JobRole />} />
            <Route path={AppRoutes.Success} element={<Success />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer position="top-center" />
      </LoginTokenContext.Provider>
    );
  }
}

export default App;
